import { post, deletes, get, downFile } from 'common/request'
export default {
  /**
   * 获取
   */
  // 获取志愿者团队列表数据
  list: params => get('volunteerInfo/listSort', params),
  //获取网格树
  getTreeList: args => get('/scGridManagement/getTreeList', args),
}
