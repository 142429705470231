<!-- 志愿排名表格 -->
<template>
  <div>
    <ax-table
      ref="tableBox"
      :columns="columns"
      :show-search="true"
      :searchForm="searchForm"
      :showToolBar="false"
      :dataSourceApi="dataSourceApis"
      :dataSourceParams="dataSourceParams"
      :scroll="{ y: '60vh', x: '80vw' }"
    >
      <div slot="age" slot-scope="{ record }" class="operName">
        {{
          new Date().getFullYear() - parseInt(record.identityCard.substr(6, 4))
        }}
      </div>
      <div slot="cellName" slot-scope="{ record }" class="operName">
        {{ record.cellName ? record.cellName : "--" }}
      </div>
      <!-- 风险等级 -->
      <div slot="riskLevel_dictText" slot-scope="{ record }" class="operName">
        <a-button
          :class="{
            grey: record.riskLevel === '3',
            red: record.riskLevel === '1',
            yellow: record.riskLevel === '2',
          }"
          >{{ record.riskLevel_dictText }}</a-button
        >
      </div>
    </ax-table>
    <!-- 志愿者审核弹窗 -->
    <!-- <audit ref="audit" @refsh="refsh"></audit> -->
  </div>
</template>

<script>
// import audit from "./audit.vue";
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
const searchForm = [
  {
    label: "关键字",
    type: "input",
    model: "residentName",
    options: { placeholder: "姓名" },
    formItem: {},
    col: { span: 6 },
  },
  {
    label: "所属网格",
    type: "cascader",
    model: "gridId",
    options: { placeholder: "请选择" },
    formItem: {},
    col: { span: 6 },
  },
];
import api from "./api";

export default {
  //import引入的组件需要注入到对象中才能使用
  // components: { audit },
  data() {
    //这里存放数据
    return {
      api,
      options: [],
      currentAreaCode: "",
      columns: this.$ax.tools.initColumn(
        [
          {
            title: "姓名",
            dataIndex: "residentName",
            ellipsis: true,
            width: 70,
          },
          {
            title: "联系方式",
            dataIndex: "contactWay",
            ellipsis: true,
            width: 130,
          },
          {
            title: "所属网格",
            dataIndex: "gridName",
            ellipsis: true,
            width: 180,
          },
          {
            title: "活动总时长(h)",
            dataIndex: "serviceDuration",
            ellipsis: true,
            width: 180,
          }
        ],
        true //去掉序号
      ),
      searchForm: this.$common.initGridFormData(searchForm, {
        layout: "horizontal",
        labelWidth: 100,
      }),
      selectedRowKeys: [],
      currentAreaCode1: [],
      dataSourceApis: api.list,
      dataSourceParams: {auditStatus:1},
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    // 表格操作
    async actionColumnClick(args) {
      const {
        btn: { name },
        record,
      } = args;
      switch (name) {
        // 编辑
        case "audit":
          record.title = "审核";
          this.$refs.audit.openModal(record);
          break;
      }
    },
    // 根据id删除
    async popConfirm({ record }) {
      const res = await api.deleteInterviewInfoById(record.id);
      if (res.status === 200) {
        this.$message.success("删除成功");
        this.$refs.tableBox.getDataSource();
      }
    },
    refsh() {
      this.$refs.tableBox.getDataSource();
    },
    add() {
      this.$refs.volunteerInfo.openModal({ title: "新增志愿者" });
    },
    // 批量删除
    del() {
      var that = this;
      if (that.selectedRowKeys.length === 0) {
        this.$message.warning("请勾选要删除的数据！");
      } else {
        this.$confirm({
          title: "是否删除",
          content: "确认是否删除当前选中内容",
          cancelText: "取消",
          okText: "确定",
          onOk: function () {
            api
              .deleteInterviewInfoByIds(that.selectedRowKeys.join(","))
              .then((res) => {
                that.$message.success("删除成功");
                that.$refs.tableBox.getDataSource();
              });
          },
        });
      }
    },
    onSelectChange(selectedRowKeys, row) {
      this.selectedRowKeys = selectedRowKeys;
    },
    async owningGrid() {
      try {
        const res = await api.getTreeList(); // 调用接口获取数据
        if (res.success) {
          // 如果接口请求成功
          const options = this.processData(res.data); // 处理数据
          this.$refs.tableBox.$children[0].$refs.searchForm.setFormItemProp(
            "gridId",
            { options: { options, allowClear: true, placeholder: "请选择",changeOnSelect:true } }
          );
        } else {
          console.error("获取所属网格数据失败:", res.message); // 输出错误信息
        }
      } catch (error) {
        console.error("获取所属网格数据异常:", error); // 输出异常信息
      }
    },
    // 处理数据为级联选择器格式
    processData(data) {
      return data.map((item) => ({
        value: item.id,
        label: item.name,
        children: item.children ? this.processData(item.children) : [], // 递归处理子节点
      }));
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.owningGrid();
  },
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='less' scoped>
.grey {
  background-color: #e6e9ee;
  color: #2d3138;
  border: #e6e9ee;
  border-radius: 4px;
}
.red {
  background-color: #fff1f0;
  color: #cf1322;
  border-radius: 4px;
  border: #fff1f0;
}
.yellow {
  background-color: #fffbe6;
  color: #d48806;
  border-radius: 4px;
  border: #fffbe6;
}
/deep/.el-dialog__body {
  padding: 10px 20px !important;
}
/deep/ .ant-table-small.ant-table-bordered .ant-table-fixed-right {
  border-left: none;
}
</style>